import React, { useEffect, useState } from "react";
import Body from "./components/Body";


const App = () => {
  return <Body />
}


export default App;
